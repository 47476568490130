/* eslint-disable no-mixed-operators */
import { useEffect, useState, useReducer } from "react";
import ReactGA from "react-ga4";

import { useSelector, useDispatch } from "react-redux";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineFavoriteBorder, MdFavorite } from "react-icons/md";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import UpArrow from "../../assets/images/product/UpArrow.svg";
import DownArrow from "../../assets/images/product/DownArrow.svg";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReactStars from "react-rating-stars-component";
import { useLocation, useParams } from "react-router-dom";
import { BsCart2 } from "react-icons/bs";
import Slider from "react-slick";
import toast, { Toaster } from "react-hot-toast";
import { Player } from "video-react";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import moment from "moment";
import CryptoJS from "crypto-js";

// Utilities
import { getUser } from "../../utils/AuthService";

// API-Service
import {
  getSingleProduct,
  getProductReview,
} from "../../service/catalogue.service";
import { addCartService, cartListService } from "../../service/cart.service";
import { addWishlistService } from "../../service/wishlist.service";

// Higher-Order-Component
import withLayout from "../../components/higherOrderComponent/PagesLayout/PagesLayout";

// Components
import Product from "../../components/product/DynamicProductItem";
import ScrollToTop from "../../utils/ScrollToTop";
// Redux-Settings
import {
  selectIsSmallScreen,
  selectIsLoginPopup,
  setIsLoginPopup,
  setCategoryDropdownValue,
} from "../../redux/utilitySlice";

import { isLoggedIn } from "../../redux/authSlice";
import {
  setCartList,
  setSelectedCartItemsList,
  setSelectedCartItemsId,
  setDeepLink,
} from "../../redux/cartSlice";

// Styles
import "../../assets/scss/base/pages/productDetails/ProductDetails.scss";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video-react/dist/video-react.css";
import "react-tabs/style/react-tabs.css";

// Images
import ShareIcon from "../../assets/images/homepage/share-icon.png";
import { pcoin } from "../../assets/images/homepage";
import ProductPlaceHolder from "../../assets/images/placeholder/ProductPlaceHolder.svg";
import Alertircle from "../../assets/images/homepage/alert-circle.svg";
import CheckCircleBrokenIcon from "../../assets/images/homepage/check-circle-broken.svg";
import XClose from "../../assets/images/homepage/x-close.svg";
import VideoThubanail from "../../assets/images/homepage/Video-Thumbanail.png";
import minusIcon from "../../assets/images/product/minus.svg";
import plusIcon from "../../assets/images/product/plus.svg";

// Component
import LoginMessageModal from "../../components/modal/LoginMessageModal";
import Loader from "../../components/micro/loader/Loader";
import { selectWishList } from "../../redux/wishlistSlice";
import {
  selectLoginModalToggle,
  setLoginModalToggle,
} from "../../redux/modalsSlice";
import SignIn from "../authentication/SignIn";
import { Helmet } from "react-helmet";

const url = process.env.REACT_APP_PINEAPPLE_API_BACKEND_SERVER;
const imageCDN = process.env.REACT_APP_PINEAPPLE_IMAGE_CDN;

const ProductDetailsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productID = searchParams.get("productID");
  const user = getUser();
  const isLoggdin = useSelector(isLoggedIn);
  const [singleProduct, setSingleProduct] = useState({});
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const isLoginMessageModal = useSelector(selectIsLoginPopup);
  const isOpen = useSelector(selectLoginModalToggle);
  // Loader
  const [loading, setLoading] = useState(true);
  // Product Variations Controlling
  const [successfull, setSuccessfull] = useState(false);
  const [temp, setTemp] = useState("");
  const [product, setProduct] = useState("");
  const [attributes, setAttributes] = useState("");
  const [options, setoptions] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);

  const [selectedId, setSelectedId] = useState("");
  const [color, setColor] = useState("");
  const [productReview, setProductReview] = useState("");
  const [isAddedCartlist, setIsAddedCartlist] = useState(false);

  const [productThumbnailFile, setProductThumbnailFile] = useState([]);
  const { productName } = useParams();

  // Dcrypting the url
  const currentURL = window.location.href;
  let productURL = currentURL
    ?.split("?ref=")[1]
    ?.replace(product?.name?.trim()?.replace(/ /g, "-"), "")
    .trim();
  let sharedUrl = "";
  let Color = "";
  let referredProductVariationID = "";
  if (productURL) {
    sharedUrl = CryptoJS.AES.decrypt(productURL, "Pcoins").toString(
      CryptoJS.enc.Utf8
    );
    Color = sharedUrl?.split("&")[3].split("=")[1];
    referredProductVariationID = sharedUrl?.split("&")[2].split("=")[1];
  }
  //Dcrypting url Ends

  // Fetch product
  const handleFetchProduct = async () => {
    const response = await getSingleProduct({
      productId: productName,
    });
    if (response?.data?.success) {
      setSingleProduct(response?.data?.data);
      const dg = {
        ...response?.data?.data,
        ProductVariations: response?.data?.data?.ProductVariations?.map(
          (item, ind) => {
            return {
              ...item,
              attributesC: Object.keys(item?.attibutes).map((optionname) => ({
                ind,
                optionname: optionname?.toLowerCase(),
                value: item?.attibutes[optionname]?.toString(),
                id: item?.id,
                selected: false,
              })),
            };
          }
        ),
      };
      setIsAddedCartlist(dg?.isOnCart);
      dispatch(
        setCategoryDropdownValue({
          state: {
            id: dg?.MainCategory?.id,
            categoryName: dg?.MainCategory?.name,
            subCatId: dg?.SubCategory?.id,
            subCatagoryName: dg?.SubCategory?.name,
            brandId: dg?.Brand?.id,
            brandName: dg?.Brand?.name,
            SubCategory: "",
          },
        })
      );
      setTemp(JSON.parse(JSON.stringify(dg)));

      var output = [];
      var atri = dg?.ProductVariations?.map((i) => i?.attributesC);
      const d = atri?.flat(1);

      d?.forEach(function (item) {
        var existing = output.filter(function (v, i) {
          return v.optionname == item.optionname;
        });

        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].value = output[existingIndex].value.concat(
            item.value
          );
        } else {
          if (typeof item.value == "string") {
            item.value = [item.value];
            item.id = item.id;
            item.selected = "";
          }
          output.push(item);
        }
      });

      setoptions(output);
      setSelectedId(output?.find((i) => i?.optionname == "color")?.value[0]);
      if (Color) {
        setColor(Color);
      } else {
        setColor(output?.find((i) => i?.optionname == "color")?.value[0]);
      }
      if (referredProductVariationID) {
        const sharedVariation = response?.data?.data?.ProductVariations.filter(
          (variation) => {
            return variation.id == referredProductVariationID;
          }
        );
        setAttributes(sharedVariation[0]);
      } else {
        setAttributes(response?.data?.data?.ProductVariations[0]);
      }
      const responseImages = response?.data?.data?.ProductVariations[0]?.images;
      const responseVideo =
        response?.data?.data?.ProductVariations[0]?.videoUrl;
      if (responseVideo || responseImages) {
        setProductThumbnailFile([
          ...(Array.isArray(responseImages)
            ? responseImages
            : [responseImages]),
          ...(responseVideo
            ? [
                {
                  type: "video",
                  fileUrl: responseVideo,
                },
              ]
            : []),
        ]);
      } else {
        setProductThumbnailFile([]);
      }

      setProduct(dg);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleFetchProductReview = async () => {
    const response = await getProductReview({
      productId: attributes?.productId,
    });
    if (response?.data?.success) {
      setProductReview(response?.data?.data?.list);
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Product detail views",
    });
    handleFetchProduct();
    if (attributes?.productId) {
      handleFetchProductReview();
    }
  }, [productName, attributes?.productId]);

  useEffect(() => {
    if (selectedId) {
      const attributesResult = temp?.ProductVariations?.find((i) =>
        i?.attributesC?.find(
          (m) => m?.optionname == "color" && m?.value == selectedId
        )
      );

      setAttributes(attributesResult);
      if (attributesResult?.videoUrl || attributesResult?.images) {
        setProductThumbnailFile([
          ...(Array.isArray(attributesResult?.images)
            ? attributesResult?.images
            : [attributesResult?.images]),
          ...(attributesResult?.videoUrl
            ? [
                {
                  type: "video",
                  fileUrl: attributesResult?.videoUrl,
                },
              ]
            : []),
        ]);
      } else {
        setProductThumbnailFile([]);
      }
    }
  }, [selectedId]);

  const fetchCartList = async () => {
    const response = await cartListService({ filter: "" });
    if (response?.data?.success) {
      dispatch(setCartList(response?.data?.data?.list));
      dispatch(setSelectedCartItemsList(response?.data?.data?.list));
      dispatch(
        setSelectedCartItemsId(
          response?.data?.data?.list?.map((item) => item?.id)
        )
      );
    }
  };

  const handleAddProductToCart = async () => {
    ReactGA.event({
      category: "Button Click",
      action: "Addition to cart",
      label: "Product Page",
    });
    if (attributes?.stock !== 0) {
      // Track a custom event
      // Continue with your button's click handler
      const response = await addCartService({
        productId: attributes?.productId,
        productVariationId: attributes?.id,
        quantity: productQuantity.toString(),
      });
      if (response?.data?.success) {
        // const currentURL = window.location.href;

        //  if(ref){

        // const shareUrl = CryptoJS.AES.decrypt(ref, 'Pcoins').toString(
        //   CryptoJS.enc.Utf8
        // );
        if (sharedUrl) {
          const referredUserId = sharedUrl?.split("&")[0];
          const referredProductID = sharedUrl?.split("&")[1].split("=")[1];

          const referredProductIds = [referredProductVariationID];
          if (attributes?.id == referredProductVariationID) {
            dispatch(
              setDeepLink({
                productId: referredProductIds,
                userId: referredUserId,
              })
            );
          }
        }

        setIsAddedCartlist(true);
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={CheckCircleBrokenIcon} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>Successfully added to cart!</h4>
                <p>This product is successfully added to cart list</p>
              </div>
              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#12B76A",
              color: "#C5F6DF",
            },
          }
        );
        fetchCartList();
      } else {
        toast(
          (t) => (
            <div className="toast__block">
              <div className="toast__block__icon">
                <img src={Alertircle} alt="" />
              </div>
              <div className="toast__block__content">
                <h4>{response?.data?.errorMessage}</h4>
                <p>This product is not added to cart list</p>
              </div>

              <button onClick={() => toast.dismiss(t.id)}>
                <img src={XClose} alt="" />
              </button>
            </div>
          ),
          {
            style: {
              borderRadius: "10px",
              background: "#D92D20",
              color: "#FFD7D3",
            },
          }
        );
      }
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Product out of stock</h4>
              {/* <p>This product is not added to cart list</p> */}
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  const handleAddProductToWishlist = async () => {
    const response = await addWishlistService({
      productId: attributes?.productId,
      productVariationsId: attributes?.id,
    });

    if (response?.data?.success) {
      setSuccessfull(response?.data?.success);
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={CheckCircleBrokenIcon} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Item added into your wishlist!</h4>
              <p>This item is successfully added to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#12B76A",
            color: "#C5F6DF",
          },
        }
      );
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>{response?.data?.errorMessage}</h4>
              <p>This product is not added to wishlist</p>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };

  const handleShareAndEarn = () => {
    if (isLoggdin) {
      var cypertext = CryptoJS.AES.encrypt(
        `${user?.id}&productId=${attributes?.productId}&variationId=${attributes?.id}&color=${selectedId}`,
        "Pcoins"
      );

      const currentURL = window.location.href;

      navigator.clipboard
        .writeText(`${currentURL}?ref=${cypertext}`)
        .then(() => {
          toast(
            (t) => (
              <div className="toast__block">
                <div className="toast__block__icon">
                  <img src={CheckCircleBrokenIcon} alt="" />
                </div>
                <div className="toast__block__content">
                  <h4>URL Copied!</h4>
                  <p>The URL is copied, you may share it now.</p>
                </div>

                <button onClick={() => toast.dismiss(t.id)}>
                  <img src={XClose} alt="" />
                </button>
              </div>
            ),
            {
              style: {
                borderRadius: "10px",
                background: "#12B76A",
                color: "#C5F6DF",
              },
            }
          );
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      toast(
        (t) => (
          <div className="toast__block">
            <div className="toast__block__icon">
              <img src={Alertircle} alt="" />
            </div>
            <div className="toast__block__content">
              <h4>Please login first for enabling sharing.</h4>
            </div>

            <button onClick={() => toast.dismiss(t.id)}>
              <img src={XClose} alt="" />
            </button>
          </div>
        ),
        {
          style: {
            borderRadius: "10px",
            background: "#D92D20",
            color: "#FFD7D3",
          },
        }
      );
    }
  };
  // const handleTestReferral = () => {
  //   const currentURL = window.location.href;
  //   let productURL = currentURL.split('productName=')[1]?.replace(product?.name?.trim()?.replace(/ /g, "-"), '').trim();
  //   const shareUrl = CryptoJS.AES.decrypt(productURL, 'Pcoins').toString(
  //     CryptoJS.enc.Utf8,
  //   );
  //   const referredUserId = shareUrl?.split('?userId=')[1]
  //   console.log("shareUrl", shareUrl)
  //   console.log("referredUserId", referredUserId)
  // }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#d3d3d3" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#d3d3d3" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const relatedSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerPadding: "60px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 5,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1385,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 4,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1143,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          ariableWidth: true,
          arrows: false,
          variableWidth: true,
        },
      },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     initialSlide: 2,
      //     infinite: true,
      //     ariableWidth:true,
      //     arrows:false,
      //     variableWidth:true,

      //   },
      // },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          variableWidth: true,
          arrows: false,
        },
      },
    ],
  };

  const carousel = document.querySelector("#carousel");
  // const cardWidth= document?.querySelector("#card")?.offsetWidth;
  // console.log(cardWidth,"cardWidth")
  const handleCarousel = (id) => {
    try {
      if (id == "left") {
        carousel.scrollTop = carousel.scrollTop - 80;
      } else {
        carousel.scrollTop = carousel.scrollTop + 80;
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <>
      <Helmet>
        {product?.name ? <title>{product?.name}</title> : <title>P coin</title>}
        <meta name="title" content={product?.name} />

        <meta
          data-rh="true"
          property="og:description"
          name="og:description"
          content={product?.metadata?.metaDesc}
        />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_PINEAPPLE_IMAGE_CDN +
            (product?.thumbnail
              ? "/" + product.thumbnail
              : "/public/content-images/pcoins_ogimage_d.png")
          }
        />
      </Helmet>

      <div className="productDetails__page__block">
        <ScrollToTop />

        <>
          {loading ? (
            <Loader /> // Display the loader while loading is true
          ) : (
            <>
              <div className="productDetails__showcase__block">
                <div className="productDetails__media__showcase__block">
                  {isSmallScreen ? (
                    <>
                      {attributes?.images?.length > 0 ? (
                        <Slider {...settings}>
                          {attributes?.images?.map((item) => {
                            return (
                              <div key={item}>
                                <img src={`${imageCDN}/${item}`} alt="" />
                              </div>
                            );
                          })}
                          {attributes?.videoUrl && (
                            <ReactPlayer
                              url={attributes?.videoUrl}
                              controls
                              width="100%"
                            />
                          )}
                        </Slider>
                      ) : (
                        <img src={ProductPlaceHolder} alt="" />
                      )}
                    </>
                  ) : (
                    <>
                      {productThumbnailFile?.length > 0 ? (
                        <Tabs defaultIndex={0}>
                          <div className="carousel-container">
                            <TabList>
                              <div id="carousel">
                                {attributes?.images?.length > 3 && (
                                  <span>
                                    <img
                                      src={UpArrow}
                                      id="left"
                                      onClick={(e) =>
                                        handleCarousel(e.target.id)
                                      }
                                      style={{ marginLeft: "-2.5rem" }}
                                      alt="icon"
                                    />
                                  </span>
                                )}

                                {attributes?.images?.map((item) => {
                                  return (
                                    <Tab
                                      key={item}
                                      style={{ marginBottom: "0.5rem" }}
                                    >
                                      <img src={`${imageCDN}/${item}`} alt="" />
                                    </Tab>
                                  );
                                })}
                                {attributes?.videoUrl && (
                                  <Tab key={attributes?.videoUrl}>
                                    <img src={VideoThubanail} alt="" />
                                  </Tab>
                                )}
                                {attributes?.images?.length > 3 && (
                                  <span>
                                    <img
                                      src={DownArrow}
                                      id="right"
                                      onClick={(e) =>
                                        handleCarousel(e.target.id)
                                      }
                                      style={{ marginLeft: "-2.5rem" }}
                                      alt="icon"
                                    />
                                  </span>
                                )}
                              </div>
                            </TabList>
                          </div>
                          {attributes?.images?.map((item) => {
                            return (
                              <TabPanel key={item}>
                                <img src={`${imageCDN}/${item}`} alt="" />
                              </TabPanel>
                            );
                          })}
                          {attributes?.videoUrl && (
                            <TabPanel
                              key={attributes?.videoUrl}
                              style={{ width: "100%" }}
                            >
                              <ReactPlayer
                                url={attributes?.videoUrl}
                                controls
                                width="100%"
                              />
                            </TabPanel>
                          )}
                        </Tabs>
                      ) : (
                        <img src={ProductPlaceHolder} alt="" />
                      )}
                    </>
                  )}
                </div>
                <div className="productDetails__content__showcase__block">
                  <h2 className="productDetails__content__title">
                    {product?.name}
                  </h2>
                  <div className="productDetails__content__ratting__block">
                    <ReactStars
                      count={5}
                      value={product?.averageRating}
                      size={24}
                      edit={false}
                      emptyIcon={<AiFillStar />}
                      halfIcon={<AiFillStar />}
                      fullIcon={<AiFillStar />}
                      activeColor="#168952"
                    />
                    <span>{product?.reviewCount} Ratings</span>
                  </div>
                  <div className="productDetails__content__pricing__block">
                    <p className="productDetails__content__pricing--on--pricing">
                      {!product?.isOnRedemption && (
                        <p style={{ fontSize: "17px" }}>RM</p>
                      )}
                      {Number(
                        attributes?.price -
                          (attributes?.discount / 100) * attributes?.price
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      {product?.isOnRedemption && "P-Coins"}
                    </p>
                    {attributes?.discount > 0 && (
                      <p
                        className="productDetails__content__pricing--off--pricing"
                        style={{ margintop: "7px" }}
                      >
                        RM
                        {attributes?.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    )}

                    {Number(attributes?.discount) > 0 ? (
                      <p className="productDetails__content__pricing--discount--pricing">
                        {Math.floor(attributes?.discount)}% OFF
                      </p>
                    ) : null}
                  </div>
                  <div className="productDetails__content__coins__block">
                    <span className="productDetails__content__coins--off--coins">
                      You can earn
                    </span>
                    <span className="productDetails__content__coins--on--coins">
                      <img src={pcoin} alt="" />
                      {attributes?.earningPcoins} P-Coins
                    </span>
                  </div>
                  {attributes?.features && (
                    <div
                      className="Feature__Block"
                      style={{ marginBottom: "1rem" }}
                    >
                      <h5>Features :</h5>
                      <div className="productDetails__details__block">
                        {attributes?.features?.replace(/''/g, "'")
                          ? parse(
                              `${attributes?.features
                                ?.replace(/<img[^>]*>/g, "")
                                .replace(/''/g, "'")}`
                            )
                          : null}
                      </div>
                    </div>
                  )}
                  <div className="productDetails__content__color__block">
                    {product?.ProductVariations[0]?.attributes?.Color !==
                      "Default" &&
                      product?.ProductVariations[0]?.attributes?.Color !==
                        "other" && (
                        <>
                          <h5>Color: </h5>
                          {options
                            ?.filter((item) => item.optionname === "color")
                            ?.map((item) => {
                              const col = [...new Set(item.value)];
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                  }}
                                >
                                  {col.map((it) => {
                                    return (
                                      <div
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "-webkit-center",
                                        }}
                                        onClick={() => {
                                          setColor(it);
                                          setSelectedId(it);
                                          setIsAddedCartlist(false);
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: `2px solid ${
                                              color === it
                                                ? "#D71513"
                                                : "#d3d3d3"
                                            }`,
                                            height: 36,
                                            width: 36,
                                            borderRadius: 20,
                                            backgroundColor: it.toLowerCase(),
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: `${
                                              color === it
                                                ? "#D71513"
                                                : "#1C1C1C"
                                            }`,
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                            textTransform: "capitalize",
                                          }}
                                          className="productDetails__content__color--name"
                                        >
                                          {it.toLowerCase()}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                        </>
                      )}
                  </div>

                  <div
                    style={{ marginTop: "2rem" }}
                    className="productDetails__content__variation__block"
                  >
                    {temp?.ProductVariations?.map((item, index) => {
                      if (
                        item?.attributesC?.find(
                          (i) => i?.optionname === "color" && i?.value === color
                        )
                      )
                        return (
                          <div
                            key={index} // Add a key prop
                            className="productDetails__content__variation--attributes--block"
                            onClick={() => {
                              setAttributes(item);
                            }}
                            style={{
                              borderColor:
                                attributes?.id === item?.id
                                  ? "#D71513"
                                  : "#ededed",
                            }}
                          >
                            <div>
                              {item?.attributesC?.map((pro, proIndex) => (
                                <div key={proIndex}>
                                  <p
                                    style={{
                                      color:
                                        attributes?.id === item?.id
                                          ? "primary"
                                          : "blackshade",
                                    }}
                                  >
                                    {pro?.value !== "Default" &&
                                      pro?.value !== "other" &&
                                      pro?.value}
                                  </p>
                                </div>
                              ))}
                              <p>
                                RM{" "}
                                {Number.parseFloat(
                                  item?.price -
                                    (item?.discount / 100) * item?.price
                                ).toFixed(2)}
                              </p>
                              <div className="productDetails__divider" />
                              <div></div>
                              <p
                                style={{
                                  color:
                                    item?.stock > 0 ? "#007161" : "#D71513",
                                }}
                              >
                                {item?.stock > 0 ? "In Stock" : "Out Of Stock"}
                              </p>
                            </div>
                          </div>
                        );
                    })}
                  </div>

                  <p>
                    {attributes?.stock > 0 ? (
                      <span style={{ color: "green" }}>In Stock</span>
                    ) : (
                      <span style={{ color: "#D71513" }}>Out Of Stock</span>
                    )}
                  </p>

                  <span className="productDetails__content--sku">
                    SKU: {attributes?.sku}
                  </span>
                  <div className="Product__Page__Quantity__Container">
                    <div className="Product__Page__Quantity__Inner__Container">
                      <span
                        style={{ cursor: "Pointer" }}
                        onClick={() =>
                          productQuantity > 1 &&
                          setProductQuantity(productQuantity - 1)
                        }
                      >
                        <img src={minusIcon} alt="minusIcon" />
                      </span>
                      <span style={{ color: "#d71513", fontSize: "18px" }}>
                        {productQuantity}
                      </span>
                      <span
                        style={{ cursor: "Pointer" }}
                        class={
                          attributes?.stock === productQuantity && "disableBtn"
                        }
                        onClick={() => setProductQuantity(productQuantity + 1)}
                      >
                        <img src={plusIcon} alt="minusIcon" />
                      </span>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#d71513",
                      marginTop: "7px",
                      fontWeight: "500",
                    }}
                  >
                    Available Stock : {attributes?.stock}
                  </p>
                  <div className="productDetails__content__btn__block">
                    {attributes?.isOnCart || isAddedCartlist ? (
                      <button className="productDetails__content__btn--add-to-cart add-to-cart-btn-disable">
                        Added to cart
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (isLoggdin) {
                            handleAddProductToCart();
                          } else {
                            dispatch(setLoginModalToggle(true));
                          }
                        }}
                        className="productDetails__content__btn--add-to-cart"
                      >
                        <BsCart2 />
                        ADD TO CART
                      </button>
                    )}
                    <button
                      onClick={() => {
                        if (isLoggdin) {
                          handleAddProductToWishlist();
                        } else {
                          dispatch(setLoginModalToggle(true));
                        }
                      }}
                      className="productDetails__content__btn--add-to-wishlist"
                    >
                      {singleProduct?.ProductVariations[0]?.isOnWishlist ||
                      successfull ? (
                        <MdFavorite style={{ color: "red" }} />
                      ) : (
                        <MdOutlineFavoriteBorder />
                      )}
                      WISHLIST
                    </button>
                  </div>
                </div>
              </div>

              <div className="productDetails__details__review__block">
                <button
                  onClick={handleShareAndEarn}
                  className="earn__p-coins__btn"
                >
                  <img src={ShareIcon} alt="" />
                  Earn
                  <img src={pcoin} alt="" />
                  {attributes?.referCommission} P-Coins
                </button>
                {/* <button onClick={handleTestReferral}>Test Referral</button> */}

                <Tabs defaultIndex={0}>
                  <TabList>
                    <Tab>Description</Tab>
                    <Tab>Reviews</Tab>
                    {/* <Tab>Features</Tab> */}
                  </TabList>
                  <TabPanel>
                    <div
                      style={{ paddingLeft: "12px" }}
                      className="productDetails__details__block"
                      dangerouslySetInnerHTML={{
                        __html: product?.description?.replace(/''/g, "'"),
                      }}
                    />
                    {/* {product?.description  }

                    </div> */}
                  </TabPanel>
                  <TabPanel>
                    <div
                      style={{ paddingLeft: "12px" }}
                      className="productDetails__review__block"
                    >
                      <div className="productDetails__review__title__block">
                        <h3>Ratings & Review ({product?.reviewCount})</h3>
                        <div className="productDetails__review__stars__block">
                          <span>{product?.averageRating}</span>
                          <ReactStars
                            count={5}
                            value={product?.averageRating}
                            size={24}
                            edit={false}
                            emptyIcon={<AiFillStar />}
                            halfIcon={<AiFillStar />}
                            fullIcon={<AiFillStar />}
                            activeColor="#168952"
                          />
                        </div>
                      </div>
                      <div className="productDetails__review__content__block">
                        {productReview?.length > 0 ? (
                          <>
                            {productReview?.map((reviewItem) => (
                              <div
                                className="productDetails__review__content__item__block"
                                key={reviewItem?.id}
                              >
                                <div className="productDetails__review__content__item__title__block">
                                  <div className="productDetails__review__content__item__title_ratting__block">
                                    {reviewItem?.rating}
                                    <AiFillStar />
                                  </div>
                                  <h4>{reviewItem?.review}</h4>
                                </div>
                                <span className="productDetails__review__content__item__timestamp">
                                  {reviewItem?.User?.username}{" "}
                                  {moment(reviewItem?.updatedAt).format(
                                    "DD MMM, YYYY"
                                  )}{" "}
                                </span>
                              </div>
                            ))}
                          </>
                        ) : (
                          <p>No reviews</p>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="productDetails__details__block">
                      {attributes?.features
                        ? parse(`${attributes?.features}`)
                        : null}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>

              <div className="Parent"></div>
              <div className="related__product__block">
                <h3 className="related__product__title">Related Products</h3>
                <div
                  className={
                    singleProduct?.relatedProducts?.length <= 3 &&
                    "related__product__wrap"
                  }
                >
                  {singleProduct?.relatedProducts?.length > 4 ? (
                    <Slider {...relatedSettings}>
                      {singleProduct?.relatedProducts?.map((product) => (
                        <div
                          className="related__product__item"
                          key={product?.id}
                        >
                          <div style={{ width: "230px", margin: "0.5rem" }}>
                            <Product product={product} />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : singleProduct?.relatedProducts?.length > 0 ? (
                    singleProduct?.relatedProducts?.map((product) => (
                      <div key={product?.id} style={{ width: "238px" }}>
                        <Product product={product} />
                      </div>
                    ))
                  ) : (
                    <p>There is no product found</p>
                  )}
                </div>
              </div>
            </>
          )}
        </>
        <Toaster position="top-right" />
        {/* {isOpen && <SignIn/>} */}
      </div>
    </>
  );
};

export default withLayout(ProductDetailsPage, {
  isProductPage: true,
  isNestedPageName: true,
});
